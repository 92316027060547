<template>
  <div>
    <base-page-title :titleText="pageTitle" />
    <b-row class="no-gutters p-3" v-if="!isClientMultiuser">
      <b-button class="ml-auto" @click="openInvoiceModal">
        {{ $t("button.addInvoice") }}
      </b-button>
    </b-row>
    <b-col class="col-12 col-md-8 p-3 mx-auto">
      <b-card no-body class="p-3 border-0">
        <div
          v-if="isInvoiceListEmpty"
          class="font-italic text-uppercase text-center"
        >
          {{ $t("page.clientInvoices.noInvoiceMessage") }}
        </div>
        <div>
          <div v-for="(invoice, index) in invoiceList" :key="index">
            <client-invoice-list-item
              :invoice="invoice"
              :branch="branchDetails(invoice)"
              @editInvoice="editInvoice"
            />
          </div>
        </div>
      </b-card>
    </b-col>
    <client-invoice-modal
      :invoiceModalState="invoiceModalState"
      :formType="invoiceFormType"
      :invoice="invoiceInfo"
      @updateInvoiceState="updateInvoiceState"
      @createInvoice="createInvoice"
      @updateInvoice="updateInvoice"
      @deleteInvoice="deleteInvoice"
      @cancel="cancelInvoiceChanges"
    />
  </div>
</template>

<script>
import ClientInvoiceListItem from "./../components/Client/ClientInvoiceListItem";
import ClientInvoiceModal from "./../components/Client/ClientInvoiceModal";

export default {
  components: {
    ClientInvoiceListItem,
    ClientInvoiceModal
  },
  data() {
    return {
      pageTitle: "page.clientInvoices.title",
      client: null,
      clientId: "",
      invoiceModalState: false,
      invoiceInfo: {},
      invoiceFormType: ""
    };
  },
  created() {
    this.clientId = this.$route.params.id;
    this.client = this.$store.getters["clients/getClientList"].find(
      (client) => client._id === this.$route.params.id
    );
    this.initInvoiceInfo();
  },
  computed: {
    invoiceList() {
      return this.$store.getters["billing/getInvoiceList"];
    },
    isInvoiceListEmpty() {
      return this.invoiceList.length === 0;
    },
    isClientMultiuser() {
      return this.client.multiUser;
    },
  },
  methods: {
    initInvoiceInfo() {
      this.invoiceInfo = {
        number: "",
        amount: null,
        date: "",
        itemsPurchased: null,
        type: ""
      };
      this.invoiceFormType = "NEW";
    },
    branchDetails(invoice) {
      if (this.isClientMultiuser) {
        return this.client.branches.find((branch) => {
          return invoice.branchId === branch._id;
        });
      }
    },
    openInvoiceModal() {
      this.invoiceModalState = true;
    },
    updateInvoiceState(state) {
      this.invoiceModalState = state;
    },
    openLoader(loaderSettings = {}) {
      this.$store.commit("loader/SET_LOADER", loaderSettings);
    },
    closeLoader() {
      this.$store.dispatch("loader/close");
    },
    createInvoice(invoice) {
      this.openLoader();
      this.$store
        .dispatch("billing/createInvoice", { clientId: this.clientId, invoice })
        .then(() => {
          this.invoiceModalState = false;
          this.initInvoiceInfo();
        })
        .catch(() => {})
        .finally(() => {
          this.closeLoader();
        });
    },
    updateInvoice(invoice) {
      this.openLoader();
      this.$store
        .dispatch("billing/updateInvoice", { clientId: this.clientId, invoice })
        .then(() => {
          this.invoiceModalState = false;
          this.initInvoiceInfo();
        })
        .catch(() => {})
        .finally(() => {
          this.closeLoader();
        });
    },
    deleteInvoice(invoiceId) {
      this.openLoader();
      this.$store
        .dispatch("billing/deleteInvoice", {
          clientId: this.clientId,
          invoiceId
        })
        .then(() => {
          this.invoiceModalState = false;
          this.initInvoiceInfo();
        })
        .catch(() => {})
        .finally(() => {
          this.closeLoader();
        });
    },
    cancelInvoiceChanges() {
      this.initInvoiceInfo();
    },
    editInvoice(invoice) {
      this.invoiceInfo = { ...invoice };
      this.invoiceFormType = "EDIT";
      this.openInvoiceModal();
    }
  }
};
</script>

<style scoped></style>
