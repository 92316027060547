<template>
  <div>
    <b-row class="no-gutters align-items-center">
      <b-col class="col-12 col-lg body__1 pr-0 pr-0 pr-md-2">
        {{ invoiceNumber }}
      </b-col>
      <b-col
        class="col-12 col-lg text-muted body__2 text-left text-lg-right pr-1"
      >
        {{ invoicePrice | price }} &euro;
      </b-col>
      <b-col
        class="col-12 col-lg co text-muted body__2 text-left text-lg-right"
      >
        {{ invoiceDate | date(dateFormat) }}
      </b-col>
      <b-col
        class="col-12 col-lg co text-muted body__2 text-left text-lg-right"
      >
        {{
          $t("page.clientInvoices.totalVideos", {
            total: invoiceItemsPurchased,
          })
        }}
      </b-col>
      <b-col
        class="col-12 col-lg text-muted body__2 text-left text-lg-right pr-1"
        v-if="branch"
      >
        {{ branchName }}
        <span class="text-lowercase">
          {{ $t("branch") }}
        </span>
      </b-col>
      <b-col class="col-12 col-lg mt-3 ml-auto mt-lg-0">
        <b-row class="no-gutters">
          <b-button size="sm" @click="editInvoice" class="ml-auto">
            {{ $t("button.edit") }}
          </b-button>
        </b-row>
      </b-col>
    </b-row>
    <hr class="my-3" />
  </div>
</template>

<script>
export default {
  props: {
    invoice: {
      type: Object,
      required: true,
    },
    branch: {
      type: Object,
    },
  },
  data() {
    return {
      dateFormat: "DD/MM//YYYY"
    };
  },
  computed: {
    invoiceNumber() {
      return this.invoice.number;
    },
    invoicePrice() {
      return this.invoice.amount;
    },
    invoiceDate() {
      return this.invoice.date;
    },
    invoiceItemsPurchased() {
      return this.invoice.itemsPurchased;
    },
    branchName() {
      return this.branch.name;
    },
  },
  methods: {
    editInvoice() {
      this.$emit("editInvoice", this.invoice);
    }
  }
};
</script>

<style scoped></style>
